.stepper-outer-container {
  width: 60%;

  .form-fields-container {
    display: flex;
    flex-flow: column wrap;
    gap: 10px;
  }

  .instagram-step-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .instagram-auth-actions-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    button {
      width: fit-content;
    }
  }

  .certificate-display-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      width: max-content;
      margin: 12px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .stepper-outer-container {
    width: 100%;
    padding: 0px 10px;
  }
}
