.selector-container {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .selector-container {
    width: 100%;
    padding: 0px 10px;
  }
}
