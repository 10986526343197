.stepper-outer-container {
  width: 60%;

  .form-fields-container {
    display: flex;
    flex-flow: column wrap;
    gap: 10px;
  }

  .tweet-step-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .token-view {
      display: flex;
      line-height: 1.7rem;
      align-items: center;
      border: 3px solid #ba68c8;
      border-radius: 12px;
      width: fit-content;
      padding: 16px;
      margin: 1% 0px;
    }
  }

  .certificate-display-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      width: max-content;
      margin: 12px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .stepper-outer-container {
    width: 100%;
    padding: 0px 10px;
  }
}
